import React from "react";

function ScrollIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 53 28" fill="none">
      <path d="M.5 1l26 26 26-26" stroke="#fff" />
    </svg>
  );
}

export default ScrollIcon;
