import React from "react";
import { Link, graphql } from "gatsby";

import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill";
import Blurb from "../components/Blurb";
import Section from "../components/Section";
import ScrollIcon from "../icons/ScrollIcon";
import styles from "./index.module.css";
import Icons from "../components/Icons";

class Index extends React.Component {
  render() {
    const { splash, shareInfo, blurb, extraBlurb } = this.props.data.page;
    splash.image.childImageSharp.fluid.sizes = `95vw`;

    return (
      <>
        <Helmet>
          <meta property="og:title" content="Lunsford Ridge Farm" />
          <meta property="og:url" content="https://www.lunsfordridgefarm.com" />
        </Helmet>
        {shareInfo && shareInfo.image && shareInfo.description && (
          <Helmet>
            <meta name="description" content={shareInfo.description} />
            <meta property="og:description" content={shareInfo.description} />
            <meta
              property="og:image"
              content={`https://www.lunsfordridgefarm.com${shareInfo.image.childImageSharp.fixed.src}`}
            />
          </Helmet>
        )}
        <Section full>
          <div className={styles.landing}>
            <Img
              style={{ height: "100%" }}
              loading="eager"
              fluid={splash.image.childImageSharp.fluid}
              fadeIn={false}
              alt="Lunsford Welcome"
            />
            <div className={styles.landing__text}>
              <div className={styles.landing__header}>{splash.header}</div>
              <div className={styles.landing__subheader}>
                {splash.subheader}
              </div>
              <div className={styles.landing__scroll}>SCROLL</div>
              <ScrollIcon className={styles.landing__icon} />
            </div>
          </div>
        </Section>
        <Section extraClasses={[styles.iconsection]}>
          <Link to="/shop" className={styles.shopbutton}>
            SHOP NOW
          </Link>
          <Icons />
        </Section>
        <Section>
          <Blurb blurb={blurb} />
        </Section>
        <Section grey>
          <Blurb flipped blurb={extraBlurb} />
        </Section>
      </>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    page: pagesJson(pageName: { eq: "index" }) {
      ...BlurbFragment
      ...ExtraBlurbFragment
      ...OpenGraphFragment
      splash {
        header
        subheader
        image {
          childImageSharp {
            fluid(quality: 100, cropFocus: ATTENTION, maxWidth: 1250) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
