import React from "react";
import Img from "gatsby-image/withIEPolyfill";
import styles from "./Blurb.module.css";
import breakpoints from "../style/Breakpoints";

export default function Blurb(props) {
  const { image, markdown } = props.blurb;
  const sizes = `(min-width: ${breakpoints.medium}) 40vw, 100vw`;
  image.childImageSharp.fluid.sizes = sizes;
  return (
    <div
      className={`${styles.wrapper} ${
        props.flipped ? styles.wrapperFlipped : ""
      }`}
    >
      <Img
        className={styles.image}
        fluid={image.childImageSharp.fluid}
        fadeIn={false}
        alt="Lunsford Welcome"
      />
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: markdown }}
      />
    </div>
  );
}
