import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import styles from "./Icons.module.css";
import breakpoints from "../style/Breakpoints";

export default function Icons(props) {
  const { allShopJson } = useStaticQuery(graphql`
    query {
      allShopJson(sort: { fields: priority, order: DESC }) {
        edges {
          node {
            slug
            title
            icon {
              publicURL
              childImageSharp {
                fluid(
                  traceSVG: { color: "black" }
                  quality: 100
                  maxWidth: 200
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `);

  const ShopLink = ({ img, name, slug }) => {
    const sizes = `(min-width: ${breakpoints.medium}) 135px, 165px`;
    img.childImageSharp.fluid.sizes = sizes;
    return (
      <Link to={`/shop/${slug}`} className={styles.link}>
        <Img
          fluid={img.childImageSharp.fluid}
          loading="eager"
          fadeIn={false}
          className={styles.image}
          alt={name}
        />
        <p className={styles.text}>{name.toUpperCase()}</p>
      </Link>
    );
  };

  return (
    <div className={styles.wrapper}>
      {props.current && <div className={styles.seemore}>SEE MORE:</div>}
      {allShopJson.edges
        .filter((e) => e.node.slug !== props.current)
        .map((e) => (
          <ShopLink
            key={e.node.slug}
            slug={e.node.slug}
            name={e.node.title}
            img={e.node.icon}
          />
        ))}
    </div>
  );
}
